import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

function Hero() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="d-flex align-items-center justify-content-center text-center v-100 h-100">
          <Col md={10} data-aos="fade-up">
            <h1 className="hero-title">Evolucionando la Terapia Psicológica</h1>
            {/* <h2 className="hero-subtitle">
              En Psyder, conectamos a las personas con su terapeuta ideal mientras acompañamos a los profesionales en su crecimiento continuo. Juntos, mejoramos la psicoterapia
            </h2> */}
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3">
              <Button className="btn hero-btn" href="#para-psicoterapeutas">
                Únete como Psicoterapeuta
              </Button>
              <Button className="btn hero-btn" href="#para-empezar-terapia">
                Quiero Empezar Terapia
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hero;
