import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "../landing/LandingPage.css";
import PsyderBanner from "../../assets/imgs/psyder-banner.webp";
import PsyderBannerFooter from "../../assets/imgs/psyder-banner-footer.webp";

function LegalHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Psyder - Aviso Legal</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function Legal() {
  return (
    <LegalHelmet>
      <section className="info-pages">
        <div className="wrapper-banner">
          <img src={PsyderBanner} alt="Psyder Banner" className="img-fluid" />
        </div>
        <Container>
          <Row className="section-title">
            <Col>
              <h2>Aviso Legal</h2>
            </Col>
          </Row>
          <Row className="section-content">
            <Col>
              <p>
                En cumplimiento con lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI-CE), se informa a los usuarios del
                sitio web www.psyder.xyz acerca de los siguientes aspectos legales:
              </p>
              <h3>1. Datos Identificativos</h3>
              <p>
                El presente sitio web es propiedad de <strong>Sergio Villarroya Sanz</strong> (en adelante, el "Titular"), con DNI <strong>73105286-Q</strong>, con domicilio en{" "}
                <strong>Av. Teresa Salvo, 1, 44600 Alcañiz (Teruel)</strong>.
              </p>
              <p>Datos de contacto</p>
              <ul>
                <li>Teléfono: 648 64 98 85</li>
                <li>
                  Email: <a href="mailto:conecta@psyder.xyz">conecta@psyder.xyz</a>
                </li>
              </ul>

              <h3>2. Objeto del Sitio Web</h3>
              <p>
                El sitio web www.psyder.xyz tiene como finalidad ofrecer información sobre los servicios y productos relacionados con la gestión de la productividad y la tecnología, proporcionados por
                Psyder. El sitio web también permite la captación de leads a través de formularios de contacto, la suscripción a newsletters y la interacción mediante plataformas de chat en vivo.
              </p>

              <h3>3. Condiciones de Uso</h3>
              <p>
                El acceso y uso del sitio web www.psyder.xyz atribuye la condición de "Usuario" y conlleva la aceptación plena y sin reservas de todas y cada una de las disposiciones contenidas en
                este Aviso Legal, así como en los <a href="/terminos-condiciones">Términos y Condiciones de Uso</a> y en la{" "}
                <a href="https://www.iubenda.com/privacy-policy/98580626/full-legal">Política de Privacidad</a>.
              </p>
              <p>El Usuario se compromete a hacer un uso adecuado del contenido y servicios que el Titular ofrece a través del Sitio Web y, en particular, a no emplearlos para:</p>
              <ul>
                <li>Realizar actividades ilícitas o contrarias a la buena fe y al orden público.</li>
                <li>Difundir contenido o propaganda de carácter racista, xenófobo, pornográfico, de apología del terrorismo o atentatorio contra los derechos humanos.</li>
                <li>
                  Provocar daños en los sistemas físicos y lógicos del Titular, de sus proveedores o de terceras personas, introducir o difundir virus informáticos u otros sistemas que sean
                  susceptibles de provocar daños.
                </li>
              </ul>
              <p>
                El Titular se reserva el derecho de modificar, en cualquier momento y sin previo aviso, la presentación, configuración y contenidos del Sitio Web, así como las condiciones requeridas
                para su acceso y/o utilización.
              </p>

              <h3>4. Responsabilidad del Titular</h3>
              <p>
                El Titular no se responsabiliza de los errores u omisiones en los contenidos del Sitio Web ni de la falta de disponibilidad o continuidad del mismo. Tampoco garantiza la ausencia de
                virus u otros elementos dañinos que puedan causar alteraciones en el sistema informático del usuario (software y hardware), en sus documentos electrónicos o en los archivos contenidos
                en dicho sistema.
              </p>
              <p>
                El Titular se exime de cualquier responsabilidad derivada de la utilización indebida del Sitio Web por parte de los usuarios o de la vulneración de sus obligaciones contractuales,
                legales o reglamentarias.
              </p>

              <h3>5. Enlaces a sitios web de terceros</h3>
              <p>
                El Sitio Web puede incluir enlaces a sitios web de terceros. El Titular no asume ninguna responsabilidad por los contenidos, políticas o prácticas de dichos sitios web de terceros. El
                acceso a estos enlaces se realiza bajo la exclusiva responsabilidad del Usuario, quien deberá leer y aceptar los términos y condiciones de dichos sitios antes de proceder a su
                utilización.
              </p>
              <p>Asimismo, la inclusión de enlaces no implica ninguna relación, asociación, aprobación o respaldo por parte del Titular respecto de los sitios web enlazados.</p>

              <h3>6. Propiedad Intelectual e Industrial</h3>
              <p>
                Todos los contenidos del Sitio Web, entendiendo por estos a título enunciativo, pero no limitativo, los textos, fotografías, gráficos, imágenes, iconos, tecnología, software, códigos
                fuente, diseño gráfico y demás contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos fuente, son propiedad exclusiva del Titular o de terceros, cuyos derechos
                legítimamente ostenta el Titular, y están protegidos por los derechos de propiedad intelectual e industrial.
              </p>
              <p>
                Queda prohibida la reproducción, distribución, comunicación pública, transformación o cualquier otra forma de explotación, de cualquier contenido del Sitio Web sin el consentimiento
                previo, expreso y por escrito del Titular. El uso no autorizado de los contenidos del Sitio Web, así como los daños y perjuicios ocasionados en los derechos de propiedad intelectual e
                industrial del Titular, podrán dar lugar al ejercicio de las acciones que legalmente correspondan, y a las responsabilidades que de dicho ejercicio se deriven.
              </p>

              <h3>7. Protección de Datos Personales</h3>
              <p>
                De acuerdo con lo establecido en la normativa vigente en materia de protección de datos personales, el tratamiento de los datos recabados a través del Sitio Web se regirá por lo
                dispuesto en nuestra <a href="/politica-privacidad">Política de Privacidad</a>.
              </p>
              <p>
                El Titular garantiza la confidencialidad de los datos personales facilitados por los usuarios y su tratamiento conforme a las finalidades previstas, en cumplimiento con el Reglamento
                General de Protección de Datos (<strong>RGPD</strong>), y la Ley Orgánica de Protección de Datos Personales y garantía de los derechos digitales (<strong>LOPDGDD</strong>).
              </p>
              <p>
                Los usuarios pueden ejercer sus derechos de acceso, rectificación, cancelación, oposición, limitación y portabilidad de sus datos personales, en los términos establecidos por la ley,
                contactando a través del correo electrónico <a href="mailto:conecta@psyder.xyz">conecta@psyder.xyz</a>.
              </p>

              <h3>8. Legislación aplicable y jurisdicción</h3>
              <p>
                Las relaciones establecidas entre el Titular y el Usuario del Sitio Web se regirán por lo dispuesto en la legislación española. Cualquier controversia que pudiera derivarse del acceso
                o uso del Sitio Web se someterá a los juzgados y tribunales de la ciudad de <strong>Teruel</strong>, renunciando expresamente a cualquier otro fuero que pudiera corresponder.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="wrapper-banner">
          <img src={PsyderBannerFooter} alt="Psyder Banner" className="img-fluid banner-footer" />
        </div>
      </section>
    </LegalHelmet>
  );
}

export default Legal;
