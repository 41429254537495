import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import MissionImg from "./imgs/psyder-mision-img.webp";
import FirstIcon from "./imgs/psyder-especializacion-icon.webp";
import SecondIcon from "./imgs/psyder-innovacion-icon.webp";
import ThirdIcon from "./imgs/psyder-acompanamiento-icon.webp";

function Mission() {
  const items = [
    { id: 1, imgSrc: FirstIcon, text: "Especialización" },
    { id: 2, imgSrc: SecondIcon, text: "Innovación" },
    { id: 3, imgSrc: ThirdIcon, text: "Acompañamiento" },
  ];

  return (
    <Container data-aos="fade-up">
      <Row className="section-title mission-title">
        <Col>
          <h2>Misión de Psyder</h2>
        </Col>
      </Row>
      <Row className="section-content">
        <Col lg={6}>
          <p>
            En un mundo donde la <strong>salud mental</strong> es más crucial que nunca, <strong>Psyder</strong> surge para enfrentar los desafíos de una sociedad saturada de opciones y
            sobreinformación.
          </p>
          <p>
            Con la convicción de que cada persona merece acceso a una <strong>asistencia psicológica eficaz y personalizada</strong>, conectamos a las personas con su <strong>terapeuta ideal</strong>,
            utilizando un avanzado <strong>sistema de cribado impulsado por IA</strong>, asegurando que cada terapia sea efectiva y adaptada a sus necesidades específicas.
          </p>
          <p>
            Nos alejamos de las categorías diagnósticas tradicionales y limitadas para centrarnos en las <strong>soluciones</strong> que realmente abordan los problemas. <strong>Psyder</strong> es más
            que una plataforma; es un espacio donde los psicoterapeutas encuentran el <strong>apoyo personalizado</strong>, una <strong>comunidad colaborativa</strong> y los{" "}
            <strong>recursos necesarios</strong> para su <strong>desarrollo profesional</strong> y <strong>satisfacción personal</strong>.
          </p>
        </Col>
        <Col lg={6} className="text-center d-none d-lg-block image-block">
          <img src={MissionImg} alt="Ilustración de la misión de Psyder" width="450" height="auto" />
        </Col>
      </Row>
      <Row className="section-content g-4">
        {items.map((item) => (
          <Col key={item.id} md={4} className="text-center">
            <Image src={item.imgSrc} alt={`Icono ${item.text}`} roundedCircle className="mb-2 mx-auto" width="120" height="120" />
            <h3>{item.text}</h3>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Mission;
