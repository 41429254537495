import React from "react";
import { Container, Row, Col, Card, Image, Button, Carousel } from "react-bootstrap";
import { FaLinkedin, FaInstagram, FaTiktok } from "react-icons/fa";

const testimonials = [
  {
    id: 1,
    photo: "/testimonials/image1.png",
    text: "Ayudo a adultos y parejas a mejorar su bienestar emocional mediante una terapia profunda y personalizada. Combino enfoques científicos como ACT, TFE y Terapia Sistémica, junto con teoría de apego y trauma, para fomentar cambios duraderos en autoestima, relaciones y gestión emocional. Como profesional, los propósitos principales de Psyder me parecen esenciales para contribuir a la mejora de la Salud Mental de la población y mejorar la práctica clínica. Por un lado, esta plataforma ayuda a que cada persona encuentre a su terapeuta idóneo sin necesidad de ir probando muchos hasta dar con el adecuado (con todo el malestar que conlleva abrirte una y otra vez y sentir que nadie puede ayudarte). Por otro, el espacio creado para terapeutas es una comunidad donde cualquier profesional puede encontrar sostén y formaciones que aumenten calidad a su labor terapéutica. ",
    name: "Natalia Balaguer",
    position: "Psicóloga Sanitaria y Fundadora de A Tu Salud Mental.",
    social: {
      instagram: "https://www.instagram.com/atusalud_mental?igsh=MTJvOW1ncnp1a2kwZQ==",
    },
  },
  {
    id: 2,
    photo: "/testimonials/image2.png",
    text: "Actualmente, soy miembro del comité directivo de la Sociedad Española de la Psicología Clínica y de la Salud (SEPCyS) y divulgadora en Take It Simple. Mi misión y visión se alinea con las de Psyder porque trabajamos diariamente con dedicación y atendiendo a todo detalle para que las personas que reciben la terapia dispongan de la mayor calidad del servicio. Personalmente, veo imprescindible que la terapia llegue a todas las personas, máxime con el mayor rigor, eficacia y eficiencia posible. Permitir conectar a las personas que buscan ayuda y al psicoterapeuta más especializado y afín va a ser fundamental para que la alianza terapéutica entre ambas partes sea la más fructífera posible. ",
    name: "Alicia Alonso Berdún",
    position: "Psicóloga Sanitaria y Neuropsicóloga",
    social: {
      instagram: "https://www.instagram.com/takeitsimple_/",
      linkedin: "https://www.linkedin.com/in/alicia-alonso-berd%C3%BAn-258693254/"
    },
  },
  {
    id: 3,
    photo: "/testimonials/image3.png",
    text: "Aunque pueda parecer que no, encontrar a un profesional de la psicología adecuado puede ser difícil. Por eso me parece tan importante una buena plataforma de búsqueda que garantice a la persona la atención de un profesional bien formado y que se ajuste a sus necesidades, ya que la relación entre ambos es importantísima en el proceso terapéutico. Y que, además, permita al psicólogo trabajar en equipo y seguir formándose para poder crecer como profesional de forma que pueda desarrollar sus funciones con más confianza y calidad. ",
    name: "Jennifer Gómez Pérez",
    position: "Psicóloga General Sanitaria",
    social: {
      instagram: "https://www.instagram.com/jennigomezpsicologia/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D",
      tiktok: "https://www.tiktok.com/@jennigomezpsicologia"
    },
  },
  {
    id: 4,
    photo: "/testimonials/image4.png",
    text: "En ocasiones, encontrar al terapeuta adecuado puede ser un desafío, donde la comodidad y la confianza son fundamentales para el proceso terapéutico. Psyder aborda esto al ofrecer un espacio donde los pacientes pueden descubrir profesionales que se adaptan a sus necesidades, lo que les permite sentirse más cómodos y apoyados desde el inicio. Además, también proporciona a los profesionales la oportunidad de colaborar y aprender unos de otros, para mejorar las habilidades, enriqueciendo así la práctica psicológica en su conjunto. Si Psyder logra cumplir con sus objetivos, sin duda elevará el trabajo psicológico a un nuevo nivel. En resumen, Psyder se presenta como una herramienta prometedora tanto para pacientes como para profesionales, y estoy entusiasmada por ver cómo evoluciona y contribuye al campo de la psicología. ",
    name: "Andrea Remolina",
    position: "Psicóloga General Sanitaria",
    social: {
      instagram: "https://www.instagram.com/psicoandrea_/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D",
    },
  }
];

function Testimonials() {
  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Una Visión Compartida</h2>
          <h3>Referentes en Psicología comparten su entusiasmo por Psyder, una plataforma diseñada para evolucionar la experiencia terapéutica.</h3>
        </Col>
      </Row>
      <Row className="section-content">
        <Col xs={12} className="d-md-block d-none">
          <Carousel variant="dark" className="" pause="hover">
          {testimonials.reduce((acc, testimonial, index) => {
              if (index % 2 === 0) {
                acc.push([testimonial]);
              } else {
                acc[acc.length - 1].push(testimonial);
              }
              return acc;
            }, []).map((testimonialGroup, groupIndex) => (
              <Carousel.Item key={groupIndex}>
                <div className="d-flex justify-content-between">
                  {testimonialGroup.map((testimonial) => (
                    <Card key={testimonial.id} className="card-testimonial flex-fill h-100 text-center position-relative d-flex flex-column mx-2">
                      <Card.Body className="d-flex flex-column align-items-center text-center mb-4">
                        <Image src={testimonial.photo} alt={`Foto de ${testimonial.name}`} roundedCircle className="mb-4" height={180} width={180} />
                        <div className="fw-bold mb-2 div-testimonial">{testimonial.name}</div>
                        <div className="fw-bold mb-2 div-testimonial">{testimonial.position}</div>
                        <Card.Text className="mb-4 text-testimonal">{testimonial.text}</Card.Text>
                        <div className="d-flex justify-content-center gap-3 mt-auto icons-social">
                          {testimonial.social.instagram && (
                            <a href={testimonial.social.instagram} target="_blank" rel="noopener noreferrer" aria-label={`Instagram de ${testimonial.name}`}>
                              <FaInstagram size={24} />
                            </a>
                          )}
                          {testimonial.social.linkedin && (
                            <a href={testimonial.social.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`LinkedIn de ${testimonial.name}`}>
                              <FaLinkedin size={24} />
                            </a>
                          )}
                          {testimonial.social.tiktok && (
                            <a href={testimonial.social.tiktok} target="_blank" rel="noopener noreferrer" aria-label={`TikTok de ${testimonial.name}`}>
                              <FaTiktok size={24} />
                            </a>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col xs={12} className="d-md-none d-block">
          <Carousel variant="dark" className="">
            {testimonials.map((testimonial) => (
              <Carousel.Item key={testimonial.id}>
                <Card className="card-testimonial h-100 text-center position-relative d-flex flex-column">
                  <Card.Body className="d-flex flex-column align-items-center text-center mb-4">
                    <Image src={testimonial.photo} alt={`Foto de ${testimonial.name}`} roundedCircle className="mb-4" height={180} width={180} />
                    <div className="fw-bold mb-2 div-testimonial">{testimonial.name}</div>
                    <div className="fw-bold mb-2 div-testimonial">{testimonial.position}</div>
                    <Card.Text className="mb-4 text-testimonal">{testimonial.text}</Card.Text>
                    <div className="d-flex justify-content-center gap-3 mt-auto icons-social">
                      {testimonial.social.instagram && (
                        <a href={testimonial.social.instagram} target="_blank" rel="noopener noreferrer" aria-label={`Instagram de ${testimonial.name}`}>
                          <FaInstagram size={24} />
                        </a>
                      )}
                      {testimonial.social.linkedin && (
                        <a href={testimonial.social.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`LinkedIn de ${testimonial.name}`}>
                          <FaLinkedin size={24} />
                        </a>
                      )}
                      {testimonial.social.tiktok && (
                        <a href={testimonial.social.tiktok} target="_blank" rel="noopener noreferrer" aria-label={`TikTok de ${testimonial.name}`}>
                          <FaTiktok size={24} />
                        </a>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row className="section-content text-center">
        <Col>
          <a href="/#precios" rel="noopener noreferrer" aria-label={`Precios`}>
            <Button className="btn-prim mt-3 font-button">¿Compartes la visión? Únete a Psyder y empieza a evolucionar la psicoterapia</Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Testimonials;
