import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "../landing/LandingPage.css";
import PsyderBanner from "../../assets/imgs/psyder-banner.webp";
import PsyderBannerFooter from "../../assets/imgs/psyder-banner-footer.webp";
import AboutImg from "./imgs/psyder-about-1.webp";
import AboutImg2 from "./imgs/psyder-about-2.webp";

function AboutPsyderHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Psyder - La Evolución de la Terapia Psicológica</title>
        <meta
          name="description"
          content="Psyder mejora el acceso a la psicoterapia conectando a cada persona con el terapeuta adecuado mediante IA avanzada. Garantizamos una experiencia terapéutica eficaz y personalizada, mientras ofrecemos a los psicoterapeutas herramientas para su crecimiento profesional"
        />
        <meta
          name="keywords"
          content="encontrar terapeuta ideal, terapia psicológica, salud mental, psicoterapia personalizada, conexión paciente terapeuta, plataforma de psicoterapia, terapeuta especializado, IA en psicoterapia, terapia eficiente, crecimiento profesional para psicoterapeutas, bienestar mental"
        />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function AboutPsyder() {
  return (
    <AboutPsyderHelmet>
      <section className="info-pages">
        <div className="wrapper-banner">
          <img src={PsyderBanner} alt="Psyder Banner" className="img-fluid" />
        </div>
        <Container>
          <Row className="section-title">
            <Col>
              <h2>La Evolución de la Terapia Psicológica</h2>
            </Col>
          </Row>
          <Row className="section-content">
            <Col lg={6}>
              <p>
                Vivimos en un mundo que se mueve a gran velocidad, repleto de <strong>opciones</strong> entre las que poder elegir, teniendo que tomar <strong>decisiones</strong> en poco tiempo. El
                abordaje de la <strong>salud mental</strong> no es una excepción.
              </p>
              <p>
                Hoy en día, hay una gran cantidad de <strong>psicoterapeutas</strong> disponibles, pero la mayoría ofrecen <strong>servicios generales</strong>, cubriendo una amplia gama de problemas,
                sin profundizar en la especialización. Sin embargo, la realidad es que cada psicoterapeuta, aunque con una <strong>formación general</strong>, tiende a <strong>especializarse</strong>{" "}
                más en ciertos ámbitos. A pesar de esto, la <strong>alta competencia</strong> dificulta que se destaquen en sus <strong>áreas específicos</strong>. A esto se suma el desconocimiento y
                la falta de educación sobre salud mental en la población general, lo que hace que <strong>elegir al profesional adecuado</strong> sea aún más complicado. Muchas personas acaban tomando
                decisiones basadas en <strong>recomendaciones</strong> de conocidos o pasando por <strong>varios terapeutas</strong> antes de encontrar la ayuda que realmente necesitan. Esto no solo
                genera mayores <strong>costos</strong>, sino que también implica un valioso <strong>tiempo perdido</strong>. Como resultado, a menudo los <strong>procesos terapéuticos</strong> no son
                efectivos. La <strong>conexión</strong> entre la persona y el terapeuta es <strong>esencial</strong>, y cuando no se logra, aumentan las tasas de{" "}
                <strong>abandono de la terapia</strong>, la <strong>insatisfacción</strong> y, finalmente, la <strong>pérdida de confianza</strong> en la psicoterapia. Los propios psicoterapeutas
                también sufren en este entorno, enfrentando <strong>frustraciones</strong> que pueden afectar a su <strong>desempeño y bienestar.</strong>
              </p>
            </Col>
            <Col lg={6} className="d-flex flex-column align-items-center justify-content-center gap-3 pt-3 pt-lg-0">
              <img src={AboutImg} alt="Ilustración" className="img-fluid" />
            </Col>
          </Row>
          <Row className="section-content">
            <Col lg={6} className="d-flex flex-column align-items-center justify-content-center gap-3 d-none d-lg-flex">
              <img src={AboutImg2} alt="Ilustración" className="img-fluid" />
            </Col>
            <Col lg={6}>
              <p>
                <strong>Psyder</strong> nace con la misión de <strong>mejorar el proceso terapéutico</strong> en todas sus etapas, para el beneficio de quienes buscan apoyo y de quienes lo ofrecen. El
                primer paso es <strong>empezar</strong>, y por ello nos centramos en ayudar a las personas a encontrar la <strong>asistencia que realmente necesitan</strong>, de manera{" "}
                <strong>específica</strong>, enfocándonos en <strong>soluciones personalizadas</strong> más que en problemas generalistas. Queremos romper con las limitaciones de las etiquetas
                diagnósticas tradicionales y ofrecer un enfoque más amplio y comprensivo. <strong>Psyder</strong> no se limita a ser un buscador de psicoterapeutas. Es un espacio diseñado para{" "}
                <strong>respaldar y acompañar</strong> a los <strong>profesionales</strong> en su <strong>crecimiento</strong> continuo, fomentando su <strong>satisfacción</strong> personal y laboral.
                Queremos que no solo ejerzan su vocación, sino que también la disfruten plenamente. De este modo, contribuimos a que las <strong>terapias</strong> sean{" "}
                <strong>más efectivas y eficientes</strong>, mientras facilitamos que los profesionales exploren y desarrollen todo su potencial, muchas veces restringidos por las barreras del sistema
                laboral y educativo.
              </p>
              <p>
                Con <strong>Psyder</strong>, apostamos por un <strong>nuevo enfoque en la forma de realizar la terapia psicológica</strong>, donde quienes buscan ayuda y quienes la ofrecen puedan
                avanzar juntos hacia un futuro más saludable en el que tener una vida plena.
              </p>
            </Col>
          </Row>
          <Row className="section-content d-flex align-items-center justify-content-center my-5">
            <Col lg={10} className="text-center">
              <Button className="btn-prim" href="/#precios">
                Únete a Psyder y empieza a Evolucionar la Psicoterapia
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="wrapper-banner">
          <img src={PsyderBannerFooter} alt="Psyder Banner" className="img-fluid banner-footer" />
        </div>
      </section>
    </AboutPsyderHelmet>
  );
}

export default AboutPsyder;
