import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, ToggleButtonGroup, ToggleButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import LandingDivider from "../../common/Dividers/LandingDivider";

function Pricing() {
  const [billingType, setBillingType] = useState("Mensual");
  const [visibleFormId, setVisibleFormId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTables, setShowTables] = useState(false);
  const [loadedForms, setLoadedForms] = useState(new Set());

  useEffect(() => {
    if (visibleFormId) {
      if (!loadedForms.has(visibleFormId)) {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "145322866",
            formId:
              visibleFormId === "form1"
                ? "7dcf47ab-0f21-4c65-bc0a-adb46d5b5ff7"
                : visibleFormId === "form2"
                ? "5264877b-a906-48cf-a691-33096fe7d9b2"
                : visibleFormId === "form3"
                ? "fa4273be-d5cc-4ba1-8bec-4f76a8cf1883"
                : null,
            target: `#hubspotForm-${visibleFormId}`,
          });
          setLoadedForms((prev) => new Set(prev).add(visibleFormId));
        }
      }
    }
    const fixedModal = document.querySelector('.fixedModal');
    if (fixedModal) {
      fixedModal.style.display = showModal ? 'block' : 'none';
    }
  }, [showModal, visibleFormId, loadedForms]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.text}
    </Tooltip>
  );

  const pricingPlans = [
    {
      id: 1,
      title: "Plan Base",
      monthlyOriginalPrice: "39 €/mes",
      monthlyPrice: "30 €/mes",
      annualOriginalPrice: "390 €/año",
      annualPrice: "300 €/año",
      discount: "¡23% de Descuento!",
      summary: "Ideal para profesionales que buscan una solución completa y accesible para desarrollarse como psicoteraputas",
      description: (
        <ul>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Gestión de perfil con toda la información relevante y necesaria para mostrar cómo puedes ayudar" })}>
              <span>Perfil Profesional Completo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Formar parte de la base de datos y disponible para ofrecerte como la mejor opción" })}>
              <span>Aparición en Resultados <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Interacción confidencial a través de sistema de mensajería interno" })}>
              <span>Comunicación Privada y Segura con Pacientes <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Consulta de los resultados de la valoración inicial y datos de contacto" })}>
              <span>Acceso a Datos Relevantes del Paciente con su Permiso <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Organiza y gestiona citas directamente. Los pacientes podrán reservar citas" })}>
              <span>Agenda de Citas <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Facilidad de pagos con métodos rápidos y seguros como Stripe o PayPal" })}>
              <span>Pago Seguro para Pacientes <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Visitas al perfil, Nº de citas agendadas, reseñas, visualizaciones de artículos,…" })}>
              <span>Estadísticas del Perfil <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>3 Consultas al mes de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}>
              <span>2 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}>
              <span>1 Consulta al mes de Asesoramiento Profesional Individualizado (por videollamada) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "De entre todos los Grupos, podrás formar parte de 1 en el que compartir conocimiento con más profesionales, recibir feedback, y más" })}
            >
              <span>Acceso y Participación en 1 Grupo de Trabajo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Materiales gratuitos que poder utilizar en las terapias y opción de adquirir formación continua a precios asequibles" })}>
              <span>Acceso a Recursos Gratuitos y Formaciones con Descuento <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
    {
      id: 2,
      title: "Plan Estándar",
      monthlyOriginalPrice: "59 €/mes",
      monthlyPrice: "43 €/mes",
      annualOriginalPrice: "590 €/año",
      annualPrice: "430 €/año",
      discount: "¡27% de Descuento!",
      summary: "Para profesionales que buscan un mayor acceso a recursos, con más oportunidades de formación y revisión de casos",
      description: (
        <ul>
          <li>Todo lo Incluido en el Plan Base</li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>7 Consultas al mes de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}>
              <span>2 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Realiza una revisión en profundidad casos con especialistas en la materia" })}>
              <span>1 Revisión de Casos por Videollamada con un Especialista <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}>
              <span>1 Consulta al mes de Asesoramiento Profesional Individualizado (videollamada) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({
                text: "De entre todos los Grupos, podrás formar parte de 3 en los que compartir conocimiento con más profesionales, recibir feedback, y más. También podrás leer el contenido de otros Grupos",
              })}
            >
              <span>Participación en 3 Grupos de Trabajo y Acceso a Consultar el resto <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({
                text: "Materiales gratuitos que poder utilizar en las terapias, formación continua incluida en el plan y opción de adquirir formación avanzada a precios asequibles",
              })}
            >
              <span>Acceso a Recursos y Formaciones Gratuitas, con Descuento para Cursos Avanzados <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip({ text: "Posibilidad de compartir artículos previa revisión" })}>
              <span>Publicación Ilimitada de Artículos en el Blog de Psyder <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Se están preparando más herramientas y recursos con los que seguir mejorando y podrás tener acceso a ellas a precios muy asequibles" })}
            >
              <span>Acceso a Futuras Funcionalidades Avanzadas con Descuento <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
    {
      id: 3,
      title: "Plan Premium",
      monthlyOriginalPrice: "119 €/mes",
      monthlyPrice: "83 €/mes",
      annualOriginalPrice: "1190 €/año",
      annualPrice: "830 €/año",
      discount: "¡30% de Descuento!",
      summary: "Para psicoterapeutas que buscan una experiencia completa y llevar su profesión al siguiente nivel",
      description: (
        <ul>
          <li>Todo lo Incluido en los Planes Base y Estándar</li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Consulta cualquier duda que pueda aparecer respecto a las terapias que realizas mediante mensajes con un asesor de Psyder" })}
            >
              <span>Consultas Ilimitadas de Dudas Terapéuticas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Plantea cualquier caso del que quieras recibir ayuda o asesoramiento con un especialista en el tema" })}
            >
              <span>4 Revisiones al mes de Casos con Especialistas (por mensajería interna) <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Realiza una revisión en profundidad casos con especialistas en la materia" })}
            >
              <span>2 Revisiones de Casos por Videollamada con Especialista <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Asesoría personalizada para ayudarte en tu desarrollo profesional" })}
            >
              <span>2 Consultas al mes de Asesoramiento Profesional Individualizado por Videollamada <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Podrás formar parte de todos los Grupos, en los que compartir conocimiento con más profesionales, recibir feedback, y más" })}
            >
              <span>Participación en Todos los Grupos de Trabajo <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Materiales que poder utilizar en las terapias y formación continua avanzada" })}
            >
              <span>Acceso Completo a Todos los Recursos y Formaciones <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip({ text: "Se están preparando más herramientas y recursos con los que seguir mejorando y podrás tener acceso a ellas sin gastar más" })}
            >
              <span>Acceso Completo y Sin Costo Adicional a Futuras Funcionalidades Avanzadas <AiOutlineInfoCircle /></span>
            </OverlayTrigger>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Container>
      <Row className="section-title">
        <Col>
          <h2>Únete a la Evolución de la Psicoterapia con Psyder</h2>
          <h3>
            Regístrate Ahora y Aprovecha la <span className="prim-color">Oferta Pre-Lanzamiento Para Siempre</span>. Inscríbete y disfruta de una <span className="prim-color">Prueba Gratuita</span> de
            7 días desde el momento del Lanzamiento.
          </h3>
        </Col>
      </Row>
      <Row className="section-content justify-content-center">
        <Col md={5}>
          <Row>
            <Col className="d-flex align-items-end justify-content-end">
              <p className="prim-color mb-0">¡Ahorra 2 meses!</p>
            </Col>
          </Row>
          <ToggleButtonGroup type="radio" name="billingOptions" value={billingType} onChange={(value) => setBillingType(value)} className="pricing-toggle w-100 border rounded-pill">
            <ToggleButton id="tbg-radio-1" value="Mensual" className={`pricing-toggle font-button fw-bold w-50 border-0 rounded-pill ${billingType === "Mensual" ? "active" : ""}`}>
              Mensual
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value="Anual" className={`pricing-toggle font-button fw-bold w-50 border-0 rounded-pill ${billingType === "Anual" ? "active" : ""}`}>
              Anual
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row className="section-content">
        {pricingPlans.map((plan) => (
          <Col key={plan.id} lg={4} md={6} className="mb-4" data-aos="fade-up" data-aos-delay={plan.id*100}>
            <Card className="pricing-card h-100">
              <Card.Body className="text-center">
                <h4 className="mb-4">{plan.title}</h4>
                <Card.Text>
                  {billingType === "Mensual" ? (
                    <>
                      <del>{plan.monthlyOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.monthlyPrice}</span>
                    </>
                  ) : (
                    <>
                      <del>{plan.annualOriginalPrice}</del>
                      <span className="prim-color d-block fw-bold">{plan.annualPrice}</span>
                    </>
                  )}
                </Card.Text>
                <span className="third-color fw-bold d-block my-2">{plan.discount}</span>
                <p className="my-4">{plan.summary}</p>
                <Button onClick={() => { setVisibleFormId("form"+plan.id);setShowModal(true); }} className="btn-prim my-3 font-button">
                  Únete Ahora
                </Button>
                <div className="mt-4 text-start">{plan.description}</div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="text-center">
        <Col>
          <Button className="btn btn-prim mt-3 mb-3 font-button" onClick={() => setShowTables(prev => !prev)}>
            Compara todos los planes y no te pierdas nada
          </Button>
        </Col>
      </Row>
      <div className={`pricing-comparison-wrapper ${showTables ? 'show' : 'hide'}`}>
        <Row className="pricingComparison">
          <Col key={1} lg={6} sm={1} className="mb-4">
            <div className="title-plan-comparison hide-content">Características</div>
            <div className="h-100 card list-features">
              <div>Perfil Profesional Completo</div>
              <div>Aparición en Resultados</div>
              <div>Comunicación Privada y Segura con Pacientes</div>
              <div>Acesso a Datos Relevantes del Paciente</div>
              <div>Agenda de Citas</div>
              <div>Pago Seguro para Pacientes</div>
              <div>Estadísticas del Perfil</div>
              <div>Consultas de Dudas Terapéuticas <span>(por mensajería interna)</span></div>
              <div>Asesoramiento Profesional Individualizado <span>(por videollamada)</span></div>
              <div>Revisiones de Casos con Especialistas <span>(por mensajería interna)</span></div>
              <div>Revisiones de Casos con Especialistas <span>(por videollamada)</span></div>
              <div>Grupos de Trabajo</div>
              <div>Recursos Terapéuticos</div>
              <div>Formación Continua Básica</div>
              <div>Formación Continua Avanzada</div>
              <div>Publicaciones en el Blog</div>
              <div>Futuras Funcionalidades Avanzadas</div>
            </div>
            <div className="price-plan-comparison hide-content">
              Precios
            </div>
          </Col>
          <Col key={2} lg={2} sm={1} className="mb-4">
            <div className="title-plan-comparison">Base</div>
            <div className="h-100 card list-features text-center">
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div>3/mes</div>
              <div>1/mes</div>
              <div>2/mes</div>
              <div className="x-feature">x</div>
              <div>1</div>
              <div><FaCheck /></div>
              <div>Descuento 30%</div>
              <div className="x-feature">x</div>
              <div className="x-feature">x</div>
              <div className="x-feature">x</div>
            </div>
            <div className="price-plan-comparison">
              <div className="strike-text">{billingType === "Mensual" ? "39 €/mes" : "390 €/año"}</div>
              <div className="bigger-text">{billingType === "Mensual" ? "30 €/mes" : "300 €/año"}</div>
            </div>
          </Col>
          <Col key={3} lg={2} sm={1} className="mb-4">
            <div className="title-plan-comparison">Estándar</div>
            <div className="h-100 card list-features text-center">
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div>7/mes</div>
              <div>1/mes</div>
              <div>2/mes</div>
              <div>1/mes</div>
              <div>3</div>
              <div><FaCheck /></div>
              <div>Todo incluido</div>
              <div>Descuento 40%</div>
              <div>Ilimitadas</div>
              <div>Con descuento</div>
            </div>
            <div className="price-plan-comparison">
              <div className="strike-text">{billingType === "Mensual" ? "59 €/mes" : "590 €/año"}</div>
              <div className="bigger-text">{billingType === "Mensual" ? "43 €/mes" : "430 €/año"}</div>
            </div>
          </Col>
          <Col key={4} lg={2} sm={1} className="mb-4">
            <div className="title-plan-comparison">Premium</div>
            <div className="h-100 card list-features text-center">
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div><FaCheck /></div>
              <div>Ilimitadas</div>
              <div>2/mes</div>
              <div>4/mes</div>
              <div>2/mes</div>
              <div>Todos</div>
              <div><FaCheck /></div>
              <div>Todo incluido</div>
              <div>Todo incluido</div>
              <div>Ilimitadas</div>
              <div><FaCheck /></div>
            </div>
            <div className="price-plan-comparison">
              <div className="strike-text">{billingType === "Mensual" ? "119 €/mes" : "1190 €/año"}</div>
              <div className="bigger-text">{billingType === "Mensual" ? "83 €/mes" : "830 €/año"}</div>
            </div>
          </Col>
        </Row>
      </div>
      <LandingDivider />
      <Row className="justify-content-center">
        <Col lg={8} className="text-center" style={{ marginTop: 60 }}>
          <p>
            Psyder está <strong>a punto de lanzarse</strong> y estamos preparando <strong>todo para ti</strong>. Cada plan está diseñado para ofrecerte <strong>flexibilidad</strong> y los{" "}
            <strong>recursos</strong> que necesites, tanto si estás comenzando como si ya tienes una carrera consolidada. Disfruta de <strong>soporte</strong> continuo, <strong>conexiones</strong>{" "}
            significativas y oportunidades de <strong>crecimiento profesional</strong>, todo en un solo lugar.
          </p>
        </Col>
      </Row>

      <div className="fixedModal">
          <div className="closeModalBtn" onClick={() => setShowModal(false)}>X</div>
          <div>
            <div
              id="hubspotForm-form1"
              className={`hubspotForm ${visibleFormId === "form1" ? "" : "hidden"}`}
            ></div>
            <div
              id="hubspotForm-form2"
              className={`hubspotForm ${visibleFormId === "form2" ? "" : "hidden"}`}
            ></div>
            <div
              id="hubspotForm-form3"
              className={`hubspotForm ${visibleFormId === "form3" ? "" : "hidden"}`}
            ></div>
          </div>
      </div>

    </Container>
  );
}

export default Pricing;
